.layout-footer {
    @include transition(margin-left $transitionDuration);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $footerBgColor;
    padding: 1em 2em;

    img {
        vertical-align: middle;
    }

    .footer-text {
        vertical-align: middle;
    }
}